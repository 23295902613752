<template>
  <div id="index">
    <dv-full-screen-container class="bg">
      <div class="host-body">
        <!-- 第二行 -->
        <Menu
          name="safety"
          style="background: black;z-index: 999;position: relative;"
          @change="onProInfoChange($event)"
        />
        <dv-loading v-if="loading">Loading...</dv-loading>
        <div v-else class="body-box">
          <div class="content-box">
            <div style="width:6.1rem">
              <ItemWrap
                class="contetn_left-top contetn_lr-item"
                title="问题总览"
                style="height:3.17rem"
              >
                <ul class="user_Overview flex">
                  <li class="user_Overview-item" style="color: #00fdfa">
                    <div class="user_Overview_nums allnum ">
                      <dv-digital-flop
                        :config="config"
                        style="width:100%;height:100%;"
                      />
                    </div>
                    <p>问题总数</p>
                  </li>
                  <li class="user_Overview-item" style="color: #07f7a8">
                    <div class="user_Overview_nums rectification">
                      <dv-digital-flop
                        :config="rectificationconfig"
                        style="width:100%;height:100%;"
                      />
                    </div>
                    <p>已整改数</p>
                  </li>
                  <li class="user_Overview-item" style="color: #e3b337">
                    <div class="user_Overview_nums needReviewed">
                      <dv-digital-flop
                        :config="needReviewedconfig"
                        style="width:100%;height:100%;"
                      />
                    </div>
                    <p>待复查数</p>
                  </li>
                  <li class="user_Overview-item" style="color: #f5023d">
                    <div class="user_Overview_nums needRectification">
                      <dv-digital-flop
                        :config="needRectificationconfig"
                        style="width:100%;height:100%;"
                      />
                    </div>
                    <p>待整改数</p>
                  </li>
                </ul>
              </ItemWrap>
              <ItemWrap
                class="contetn_left-top contetn_lr-item"
                title="检查结果统计图"
                style="height:3.57rem"
              >
                <Echart
                  :options="riskRecordAnalysisCountoptions"
                  id="centreLeft1Chart"
                  :auto-resize="true"
                  style="height:100%"
                ></Echart>
              </ItemWrap>
              <ItemWrap
                class="contetn_left-top contetn_lr-item"
                title="问题级别统计图"
                style="height:4.77rem"
              >
                <Echart
                  :options="risklevelAnalysisCountoptions"
                  id="centreRight1Chart"
                  :auto-resize="true"
                  style="height:100%"
                ></Echart>
              </ItemWrap>
            </div>
            <div>
              <div style="height:6.75rem;width:11.75rem">
                <dv-border-box-8>
                  <div
                    class="bg-color-black"
                    style="height:6.75rem;width:11.75rem"
                  >
                    <div class="d-flex pt-3 pl-2">
                      <span>
                        <i class="iconfont icon-vector" />
                      </span>
                      <div class="d-flex">
                        <span class="fs-xl text mx-2">最新问题列表</span>
                        <dv-decoration-3
                          style="width: 200px;height: 14px;float: left;"
                        />
                      </div>
                    </div>
                    <div class="d-flex jc-center body-box">
                      <dv-scroll-board
                        style="height:5.9rem;width:11.55rem;padding-left: 18px;"
                        class="dv-scr-board"
                        :config="questRecordConfigOptions"
                      />
                    </div>
                  </div>
                </dv-border-box-8>
              </div>

              <div class="pt-2">
                <dv-border-box-8>
                  <div
                    class="bg-color-black"
                    style="height:4.65rem;width:11.75rem"
                  >
                    <div class="d-flex pt-3 pl-2">
                      <span>
                        <i class="iconfont icon-vector" />
                      </span>
                      <div class="d-flex">
                        <span class="fs-xl text mx-2">近期检查力度</span>
                        <dv-decoration-3
                          style="width: 200px;height: 14px;float: left;"
                        />
                      </div>
                    </div>
                    <div class="d-flex jc-center body-box">
                      <Echart
                        :options="tableChartOptions"
                        id="checkChart"
                        width="100%"
                        style="height:4.25rem"
                      ></Echart>
                    </div>
                  </div>
                </dv-border-box-8>
              </div>
            </div>
            <div>
              <ItemWrap
                class="contetn_left-top contetn_lr-item"
                title="最新检查状态"
                style="height:100%;width:5.65rem;overflow:hidden"
              >
                <div id="timelinecontainer">
                  <component
                    :is="components"
                    :data="timelineList"
                    :class-option="defaultOption"
                    style="height:10.85rem;width:5.45rem;overflow:hidden"
                  >
                    <!-- <vue-timeline-update
                      v-for="(i, index) in timelineList"
                      :key="index"
                      :date="new Date(i.date)"
                      animation-container="timelinecontainer"
                      :title="i.title"
                      :description="i.description"
                      :thumbnail="i.pic"
                      :category="i.status"
                      icon="code"
                      :color="i.status == '检查通过' ? 'blue' : 'red'"
                    /> -->

                    <div class="demo-theme dark">
                      <timeline timeline-theme="white" timeline-bg="#3a3939">
                        <!-- <timeline-item>
                          <img
                            slot="others"
                            src="https://user-images.githubusercontent.com/12069729/36057805-80cfc3d2-0e4e-11e8-8851-6fda091ff389.png"
                            class="icon-heart"
                          />
                          <p style="color: #fff;">
                            I wrote
                            <a href="https://github.com/luyilin/Aoba">Aoba</a>
                          </p>
                          <p style="color: #fff;" class="append">
                            A tool to create a lovely resume just with a config
                            file.
                          </p>
                        </timeline-item> -->
                        <timeline-item
                          line-color="#a6ade4"
                          v-for="(item, index) in timelineList"
                          :key="index"
                        >
                          <p style="color: #fff;">
                            {{ item.date }}
                            <a href="#">{{ item.status }}</a>
                          </p>
                          <div class="timeline-content">
                            <div class="timeline-content-left">
                              <img :src="item.pic" />
                            </div>
                            <div class="timeline-content-right">
                              <div class="line1">
                                <span>{{item.UserName}}</span>
                                <span></span>
                              </div>
                              <div class="line2">{{item.PointName}}</div>
                              <div class="line3">
                                <span
                                  class="button"
                                  :class="
                                    item.RiskFlag == 1 || item.RiskFlag == 2
                                      ? 'blue'
                                      : 'red'
                                  "
                                  >{{ item.RiskFlagStr }}</span
                                >
                                <span
                                  class="button blue"
                                  :class="
                                    item.RiskLevel <= 3 &&
                                    (item.RiskFlag == 1 || item.RiskFlag == 2)
                                      ? 'blue'
                                      : 'red'
                                  "
                                  >{{ item.RiskLevelStr }}</span
                                >
                              </div>
                            </div>
                          </div>

                          <p style="color: #fff;" class="append">
                            {{ item.description }}
                          </p>
                        </timeline-item>
                      </timeline>
                    </div>
                  </component>
                </div>
              </ItemWrap>
            </div>
          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import ProInfoCache from "@/utils/cache/ProInfoCache";
import Echart from "@/common/echart";
import moment from "moment";
import vueSeamlessScroll from "vue-seamless-scroll";
import ItemWrap from "@/components/item-wrap/item-wrap.vue"; // vue2引入方式

import { Timeline, TimelineItem, TimelineTitle } from "@/components/HTimeLine";

moment.locale("zh-cn");
let style = {
  fontSize: 24
};
export default {
  data() {
    return {
      loading: true,
      pro: {},

      userOverview: {
        needRectification: 0,
        needReviewed: 0,
        rectification: 0,
        totalNum: 0
      },
      config: {
        number: [100],
        content: "{nt}",
        style: {
          ...style,
          //stroke: "#00fdfa",
          fill: "#00fdfa"
        }
      },
      rectificationconfig: {
        number: [0],
        content: "{nt}",
        style: {
          ...style,
          // stroke: "#07f7a8",
          fill: "#07f7a8"
        }
      },
      needReviewedconfig: {
        number: [0],
        content: "{nt}",
        style: {
          ...style,
          // stroke: "#e3b337",
          fill: "#e3b337"
        }
      },
      needRectificationconfig: {
        number: [0],
        content: "{nt}",
        style: {
          ...style,
          // stroke: "#f5023d",
          fill: "#f5023d"
        }
      },
      checkChart: {
        Key: [],
        data: []
      },
      tableChartOptions: {},
      components: vueSeamlessScroll,
      defaultOption: {
        singleHeight: 340,
        limitMoveNum: 1,
        hoverStop: true,
        openWatch: true,
        step: 0.2
      },
      timelineList: [],

      riskRecordAnalysisCountoptions: {},
      riskRecordAnalysisCountData: {
        xData: ["1"],
        seriesData: [{ value: 10, name: "1" }]
      },
      risklevelAnalysisCountoptions: {},
      risklevelAnalysisCountData: {
        xData: ["合格"],
        Rectified: [0],
        NeedRectified: [0],
        NeedReviewed: [0]
      },
      questRecordConfigData: [],
      questRecordConfigOptions: {
        header: ["问题分类", "问题等级", "问题描述", "处理状态", "发现日期"],
        data: [],
        rowNum: 14, //表格行数
        //headerHeight: 35,
        //headerBGC: "#0f1325", //表头
        //oddRowBGC: "#0f1325", //奇数行
        // evenRowBGC: "#171c33", //偶数行
        index: true,
        hoverPause: false,
        waitTime: 1000,
        columnWidth: [60, 90, 90, 550, 100, 100],
        align: ["center"]
      }
    };
  },
  components: {
    Menu,
    Echart,
    Timeline,
    TimelineItem,
    TimelineTitle,
    vueSeamlessScroll,
    ItemWrap
  },
  created: function() {
    this.loading = false;
  },
  mounted() {
    this.pro = ProInfoCache.getProInfo();
    this.initForm();
  },
  watch: {
    riskRecordAnalysisCountData: {
      handler(newData) {
        this.riskRecordAnalysisCountoptions = {
          radius: "50%",
          activeRadius: "55%",
          //   title: {
          //     text: "检查结果统计图",
          //     x: "left"
          //   },
          color: [
            "#37a2da",
            "#32c5e9",
            "#9fe6b8",
            "#ffdb5c",
            "#ff9f7f",
            "#fb7293",
            "#e7bcf3",
            "#8378ea"
          ],
          tooltip: {
            trigger: "item",
            formatter: "{b} ({d}%)"
          },
          toolbox: {
            show: true
          },
          calculable: true,
          legend: {
            icon: "circle",
            type: "plain",
            textStyle: {
              //图例文字的样式
              color: "#8C8C8C",
              fontSize: 12
            },
            x: "0%", //距离左侧位置
            orient: "vertical",
            data: newData.xData,
            selectedMode: true
          },
          series: [
            {
              type: "pie",
              radius: "50%",
              activeRadius: "55%",
              center: ["50%", "50%"],
              data: newData.seriesData
            }
          ]
        };
      },
      immediate: true,
      deep: true
    },
    risklevelAnalysisCountData: {
      handler(newData) {
        this.risklevelAnalysisCountoptions = {
          //   title: {
          //     text: "问题级别统计图",
          //     x: "left"
          //   },
          tooltip: {
            trigger: "axis"
          },
          legend: {
            data: ["待整改", "待复查", "已整改"],
            bottom: 1
          },
          toolbox: {
            top: "top",
            show: true,
            feature: {
              dataZoom: { show: false },
              dataView: { readOnly: false },
              magicType: { type: ["line", "bar"] },
              restore: { show: false },
              saveAsImage: { show: true }
            }
          },
          xAxis: {
            type: "category",
            boundaryGap: true,
            data: newData.xData
          },
          yAxis: {
            type: "value",
            axisLabel: {
              formatter: "{value}"
            }
          },
          series: [
            {
              name: "已整改",
              type: "bar",
              data: newData.Rectified,
              markPoint: {
                data: [
                  { type: "max", name: "最大值" },
                  { type: "min", name: "最小值" }
                ]
              },
              markLine: {
                data: [{ type: "average", name: "平均值" }]
              }
            },
            {
              name: "待整改",
              type: "bar",
              data: newData.NeedRectified,
              markPoint: {
                data: [
                  { type: "max", name: "最大值" },
                  { type: "min", name: "最小值" }
                ]
              },
              markLine: {
                data: [{ type: "average", name: "平均值" }]
              }
            },
            {
              name: "待复查",
              type: "bar",
              data: newData.NeedReviewed,
              markPoint: {
                data: [
                  { type: "max", name: "最大值" },
                  { type: "min", name: "最小值" }
                ]
              },
              markLine: {
                data: [{ type: "average", name: "平均值" }]
              }
            }
          ]
        };
      },
      immediate: true,
      deep: true
    },
    questRecordConfigData: {
      handler(newData) {
        this.questRecordConfigOptions = {
          header: ["问题分类", "问题等级", "问题描述", "处理状态", "发现日期"],
          data: newData,
          rowNum: 12, //表格行数
          //headerHeight: 20,
          index: true,
          //carousel: "page",
          columnWidth: [60, 90, 90, 550, 100, 100],
          align: ["center"]
        };
      },
      immediate: true,
      deep: true
    },
    checkChart: {
      handler(newData) {
        this.tableChartOptions = {
          title: {
            text: "",
            subtext: ""
          },
          tooltip: {
            trigger: "axis"
          },
          legend: {
            data: ["安全检查次数"]
          },
          
          toolbox: {
            top: "top",
            show: true,
            feature: {
              dataZoom: { show: false },
              dataView: { readOnly: false },
              magicType: { type: ["line", "bar"] },
              restore: { show: false },
              saveAsImage: { show: true }
            }
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: newData.Key
          },
          yAxis: {
            type: "value",
            axisLabel: {
              formatter: "{value}"
            }
          },
          series: [
            {
              name: "安全检查次数",
              type: "line",
              data: newData.data,
              markPoint: {
                data: [
                  { type: "max", name: "最大值" },
                  { type: "min", name: "最小值" }
                ]
              }
            }
          ]
        };
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    changeTiming() {
      setInterval(() => {
        this.loadData();
      }, 100000);
    },
    onProInfoChange(e) {
      this.pro = e;
      this.initForm();
    },
    initForm() {
      this.loadData();
      this.changeTiming();
      this.cancelLoading();
    },
    loadData() {
      this.$http
        .post("/Patrol/PatrolRecord/GetRiskAnalysisCount", {
          ProId: this.pro.Id,
          PatrolType: 1
        })
        .then(resJson => {
          if (resJson.Success) {
            this.riskRecordAnalysisCountData.xData = [];
            this.riskRecordAnalysisCountData.seriesData = [];
            this.riskRecordAnalysisCountData.xData.push("合格");
            this.riskRecordAnalysisCountData.seriesData.push({
              value: resJson.Data.Qualified,
              name: "合格:" + resJson.Data.Qualified
            });

            this.riskRecordAnalysisCountData.xData.push("不合格不整改");
            this.riskRecordAnalysisCountData.seriesData.push({
              value: resJson.Data.NoNeedRectification,
              name: "不合格不整改:" + resJson.Data.NoNeedRectification
            });
            this.riskRecordAnalysisCountData.xData.push("待整改");
            this.riskRecordAnalysisCountData.seriesData.push({
              value: resJson.Data.NeedRectification,
              name: "待整改:" + resJson.Data.NeedRectification
            });
            this.riskRecordAnalysisCountData.xData.push("待复查");
            this.riskRecordAnalysisCountData.seriesData.push({
              value: resJson.Data.NeedReviewed,
              name: "待复查:" + resJson.Data.NeedReviewed
            });

            this.riskRecordAnalysisCountData.xData.push("整改通过");
            this.riskRecordAnalysisCountData.seriesData.push({
              value: resJson.Data.Rectification,
              name: "整改通过:" + resJson.Data.Rectification
            });

            this.userOverview = {
              needRectification: resJson.Data.NeedRectification,
              needReviewed: resJson.Data.NeedReviewed,
              rectification: resJson.Data.Rectification,
              totalNum:
                resJson.Data.Rectification +
                resJson.Data.NeedReviewed +
                resJson.Data.NeedRectification
            };

            this.rectificationconfig = {
              ...this.rectificationconfig,
              number: [this.userOverview.rectification]
            };
            this.config = {
              ...this.config,
              number: [this.userOverview.totalNum]
            };
            this.needReviewedconfig = {
              ...this.needReviewedconfig,
              number: [this.userOverview.needReviewed]
            };
            this.needRectificationconfig = {
              ...this.needRectificationconfig,
              number: [this.userOverview.needRectification]
            };
          }
        });

      this.$http
        .post("/Patrol/PatrolRecord/GetRiskLevelAnalysisCount", {
          ProId: this.pro.Id,
          PatrolType: 1
        })
        .then(resJson => {
          if (resJson.Success) {
            this.risklevelAnalysisCountData.xData = [];
            this.risklevelAnalysisCountData.Rectified = [];
            this.risklevelAnalysisCountData.NeedRectified = [];
            this.risklevelAnalysisCountData.NeedReviewed = [];
            for (let index = 0; index < resJson.Data.length; index++) {
              const element = resJson.Data[index];
              this.risklevelAnalysisCountData.xData.push(element.Key);
              this.risklevelAnalysisCountData.Rectified.push(element.Rectified);
              this.risklevelAnalysisCountData.NeedRectified.push(
                element.NeedRectified
              );
              this.risklevelAnalysisCountData.NeedReviewed.push(
                element.NeedReviewed
              );
            }
          }
        });

      this.$http
        .post("/Patrol/PatrolRecord/GetDataListTopFifty", {
          ProId: this.pro.Id,
          PatrolType: 1,
          RiskFlag: 3
        })
        .then(resJson => {
          if (resJson.Success) {
            this.questRecordConfigData = [];
            for (let index = 0; index < resJson.Data.length; index++) {
              const element = resJson.Data[index];
              this.questRecordConfigData.push([
                element.RiskTypeStr,
                element.RiskLevelStr,
                element.Remark,
                element.StatusStr,
                element.CheckDate.split(" ")[0]
              ])
            }
          }
        });

      this.$http
        .post("/Patrol/PatrolRecord/GetDataListTopFifty", {
          ProId: this.pro.Id,
          PatrolType: 1
        })
        .then(resJson => {
          if (resJson.Success) {
            this.timelineList = [];
            for (let index = 0; index < resJson.Data.length; index++) {
              const element = resJson.Data[index];
              this.timelineList.push({
                date:
                  element.ProcessList[element.ProcessList.length - 1]
                    .RecordDate,
                pic:
                  element.ProcessList[element.ProcessList.length - 1]
                    .ImgUrlList[0],
                title: element.TaskName,
                description:
                  element.ProcessList[element.ProcessList.length - 1].Remark,
                status: element.StatusStr,
                RiskFlag: element.RiskFlag,
                RiskFlagStr: element.RiskFlagStr,
                RiskLevelStr: element.RiskLevelStr,
                RiskLevel: element.RiskLevel,
                UserName:element.CheckUserName,
                TaskName:element.TaskName,
                PointName:element.PointName
              });
            }
          }
        });

      this.loading = true;
      this.$http
        .post("/Patrol/PatrolRecord/GetLastOneWeekCheckedRecordCount", {
          ProId: this.pro.Id,
          PatrolType: 1
        })
        .then(resJson => {
          this.loading = false;
          this.checkChart.data = [];
          if (resJson.Success) {
            resJson.Data.forEach(x => {
              this.checkChart.Key.push(x.Key);
              this.checkChart.data.push(x.Count);
            });
          }
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.user_Overview {
  display: flex;
  li {
    flex: 1;
    p {
      text-align: center;
      height: 16px;
      font-size: 16px;
    }

    .user_Overview_nums {
      width: 100px;
      height: 100px;
      text-align: center;
      line-height: 100px;
      font-size: 22px;
      margin: 50px auto 30px;
      background-size: cover;
      background-position: center center;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }

      &.bgdonghua::before {
        animation: rotating 14s linear infinite;
      }
    }

    .allnum {
      &::before {
        background-image: url("../../../assets/image/left_top_lan.png");
      }
    }

    .rectification {
      &::before {
        background-image: url("../../../assets/image/left_top_lv.png");
      }
    }

    .needReviewed {
      &::before {
        background-image: url("../../../assets/image/left_top_huang.png");
      }
    }

    .needRectification {
      &::before {
        background-image: url("../../../assets/image/left_top_hong.png");
      }
    }
  }
}

.append {
  font-size: 0.8em;
  margin-top: 3px;
  color: #646c7c;
}

a {
  color: #bf6dcf;
  font-weight: bold;
  text-decoration: none;
}

.icon-heart {
  width: 20px;
}

.demo {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-gap: 1rem;
}

.demo-theme.dark {
  //background: #3a3939;
}

.github-corner:hover .octo-arm {
  animation: octocat-wave 560ms ease-in-out;
}
@keyframes octocat-wave {
  0%,
  100% {
    transform: rotate(0);
  }
  20%,
  60% {
    transform: rotate(-25deg);
  }
  40%,
  80% {
    transform: rotate(10deg);
  }
}
@media (max-width: 500px) {
  .github-corner:hover .octo-arm {
    animation: none;
  }
  .github-corner .octo-arm {
    animation: octocat-wave 560ms ease-in-out;
  }
}
.timeline-content {
  display: flex;
  color: aliceblue;
  .timeline-content-left img {
    height: 100px;
    width: 100px;
    margin: 0 20px 0 0;
  }
  .timeline-content-right {
    .line1 {
      padding: 0 0 8px;
    }
    .line2 {
      padding: 0 0 8px;
    }
    .line3 {
      .button {
        box-shadow: 0 1px 5px 0 #18191a;
        align-self: flex-start;
        flex: 0 0 auto;
        margin-right: 10px;

        padding: 0 12px;
        font-size: 12px;
        line-height: 22px;

        display: inline-block;
        outline: 0;
        border-width: 1px;
        border-style: solid;
        border-radius: 100px;
        text-transform: uppercase;
        font-family: Heebo, "Helvetica Neue", Source Sans Pro, Helvetica, Arial,
          sans-serif;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        -webkit-tap-highlight-color: transparent;
      }
      .red {
        border-color: #e0102b;
        background-color: #e0102b;
      }
      .blue {
        background-color: #0093ee;
        border-color: #0093ee;
      }
    }
  }
}
</style>
